.custom-banner-image.col-lg-4.col-md-4.col-sm-4.col-xs-12.wow.fadeInUp.homecatimg {
    height: 203px;
}
.clear{ clear:both} 
.listboxhight{
    height: 320px;
    overflow: hidden;
}
h3, .h3 {
    font-size: 21px;
}
.boxmaximg{
    max-height: 270px;
}